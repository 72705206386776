<template>
  <div class="card-body">
    <aggregate-report-template :report-data="reportData"/>
  </div>
</template>

<script>
import AggregateReportTemplate from "@/views/SuperUserViews/Reporting/Aggregate/AggregateReportTemplate";
import {onBeforeMount, ref} from "vue";
import {API_URL} from "../../../../../Constants";
import axios from "axios";

export default {
  name: "AggregateSuperUserReport",
  components: {AggregateReportTemplate},
  setup() {

    onBeforeMount(() => {
      getReport();
    })

    const reportData = ref({
      labels: [
        "Total Users",
        "Total ESU Admins",
        "ESU Admins Active",
        "ESU Admins Inactive",
        "Total District Admins",
        "District Admins Active",
        "District Admins Inactive",
        "Total Instructors",
        "Instructors Active",
        "Instructors Inactive",
        "Total Paras",
        "Para Enabled Count",
        "Para Disabled Count",
      ],
      datasets: [{
        label: "Aggregate Engagement for In Nebraska Project Para",
        backgroundColor: ['#414a83'],
        data: []
      }]
    })

    async function getReport() {
      let get_uri = API_URL + "/reports/aggregate/all";
      // add authentication
      await axios.get(get_uri)
          .then((result) => {
            reportData.value.datasets[0].data = [
              result.data.esuAdminEnrolledCount,
              result.data.esuAdminEnabledCount,
              result.data.esuAdminDisabledCount,
              result.data.districtAdminEnrolledCount,
              result.data.districtAdminEnabledCount,
              result.data.districtAdminDisabledCount,
              result.data.instructorEnrolledCount,
              result.data.instructorEnabledCount,
              result.data.instructorDisabledCount,
              result.data.paraEnrolledCount,
              result.data.paraEnabledCount,
              result.data.paraDisabledCount
            ]
          })
    }

    return {
      reportData
    }
  }
}
</script>

<style scoped>

</style>